import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />
    <h1>Histórico de Cotización Dolar</h1>
    
    <table>
      <thead>
      <tr>
        <th>Fecha</th>
        <th>Compra</th>
        <th>Venta</th>
      </tr>
      </thead>

      <tbody>
        
      </tbody>

      <tfoot>

      </tfoot>
    </table>

    <Link to="/">Volver</Link>
  </Layout>
)

export default SecondPage
